<template>
  <div>
    <div class="flex justify-center">
      <div class="flex h-full w-full flex-col justify-center p-24 md:w-[34rem]">
        <RevStepper
          :active-step="STEP_NAMES.BANK"
          :alternative-text-back="i18n(translations.previousPage)"
          :alternative-text-close="i18n(translations.stepperClose)"
          :alternative-text-completed="i18n(translations.stepperCompleted)"
          :alternative-text-current="i18n(translations.stepperCurrent)"
          :has-close="false"
          :hasBack="hasBackButton"
          :steps
          @back="goBack"
        />

        <div class="flex flex-col justify-start pt-32">
          <span class="body-1-bold">
            {{ i18n(translations.formTitle) }}
          </span>
          <span v-if="offer?.price" class="body-1 my-16">
            {{ i18n(translations.formDescription, { price: offer.price }) }}
          </span>
          <RevSkeleton
            v-else
            class="my-16"
            height="48px"
            shape="rectangle"
            width="100%"
          />

          <FormGenerator
            :form-config
            form-id="bank-form"
            :isLoading
            submit-button-id="iban-continue"
            :submit-button-label="i18n(translations.buttonLabel)"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouteParams, useRouter } from '#imports'
import { inject, ref } from 'vue'

import { putBankDetails } from '@backmarket/http-api/src/api-specs-payout/client/missing-specs'
import type {
  FormValues,
  Input,
} from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import FormGenerator from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.vue'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevSkeleton } from '@ds/components/Skeleton'
import { RevStepper } from '@ds/components/Stepper'
import { storeToRefs } from 'pinia'

import { useResaleStepper } from '~/scopes/buyback/composables/useResaleStepper'
import { useGenerateFunnelBankDetailsForm } from '~/scopes/buyback/config/useGenerateFunnelBankDetailsForm'
import { FUNNEL_ERROR_MESSAGE } from '~/scopes/buyback/pages/constants'
import { useBuyBackStore } from '~/scopes/buyback/store'
import { CMS } from '~/scopes/cms/routes-names'

import type { ErrorData } from '../components/TheCatcher/useCatcher'
import {
  type BankApiErrors,
  useBankApiErrors,
} from '../composables/useBankApiErrors'
import { ROUTE_NAMES, STEP_NAMES } from '../constants'

import translations from './Bank.translations'

const route = useRoute()

const { openErrorToast } = useTheToast()
const tracking = useTracking()
const i18n = useI18n()
const routeParams = useRouteParams()
const router = useRouter()
const logger = useLogger()
const buybackStore = useBuyBackStore()

const isLoading = ref(false)
const { offer, hasOffer } = storeToRefs(buybackStore)
const { steps, goBack, hasBackButton } = useResaleStepper({
  activeStepName: STEP_NAMES.BANK,
  hasShippingStep: offer.value?.has_shipping_choices,
})

if (!hasOffer.value) {
  router.push({
    name: CMS.BUYBACK,
    params: {
      pageName: 'home',
    },
  })
}
const formConfig: Array<Input> = useGenerateFunnelBankDetailsForm()

const errorData = inject<ErrorData>('errorData', ref({ message: '', step: '' }))
errorData.value = {
  message: FUNNEL_ERROR_MESSAGE.BUYBACK_FUNNEL,
  step: 'Bank',
}

async function handleSubmit(values: FormValues) {
  const { skip, ...apiPayload } = values
  isLoading.value = true

  tracking.trackClick({
    zone: 'buyback',
    name: 'banking_info',
    value: {
      category: routeParams?.id,
      has_bank_info: !skip,
    },
  })

  if (!skip) {
    try {
      const body = new FormData()

      for (const [key, value] of Object.entries(apiPayload)) {
        if (value) {
          body.append(key, value.toString())
        }
      }

      await $httpFetch(putBankDetails, {
        body,
      })
    } catch (errors) {
      logger.error(FUNNEL_ERROR_MESSAGE.POST_BANK, {
        owners: ['bot-squad-circularity-customer-front'],
      })

      const apiErrors = useBankApiErrors(errors as BankApiErrors)
      let formattedErrors = ''

      if (apiErrors.error) {
        formattedErrors = apiErrors.error
      } else {
        Object.values(apiErrors).forEach((value) => {
          formattedErrors += `${value}\n`
        })
      }

      openErrorToast({
        content: formattedErrors,
      })

      isLoading.value = false

      return
    }
  }

  router.push({
    name: ROUTE_NAMES.ADDRESS,
    params: { ...route.params },
    query: route.query,
  })
}
</script>
