import type { Input } from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import { useBuybackConfig } from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { generateBacsForm } from '@backmarket/nuxt-layer-buyback/utils/generators/generateBacsForm'
import { generateIbanForm } from '@backmarket/nuxt-layer-buyback/utils/generators/generateIbanForm'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './useGenerateFunnelBankDetailsForm.translations'

export const useGenerateFunnelBankDetailsForm = (): Array<Input> => {
  const i18n = useI18n()

  const marketConfig = useBuybackConfig()

  return [
    ...((marketConfig?.iban && generateIbanForm()) || []),
    ...((marketConfig?.bacs && generateBacsForm()) || []),
    {
      id: 'skip',
      cols: 6,
      type: 'checkbox',
      validation: [],
      label: i18n(translations.formNoRibCheck),
      value: false,
      extraCss: ['w-full', 'mt-22'],
    },
  ]
}
